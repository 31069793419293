import React, { useState } from "react";
import { BiChevronLeft } from "react-icons/bi";
import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga";
import { VscArrowSmallRight } from "react-icons/vsc";
import { Link } from "react-router-dom";
import LazyLoad from 'react-lazyload';

//styles

import "../Styles/form.css";
//images
import Logo from "../../../assets/logo.svg";
import Step1 from "../../../assets/Form/1 - Active.svg";
import Step2 from "../../../assets/Form/2 - Active.svg";
import Step3 from "../../../assets/Form/3 - Active.svg";
import Step4 from "../../../assets/Form/4 - Active.svg";
import Step5 from "../../../assets/Form/5 - Active.svg";

//components
import Product from "./Product";
import UserName from "./UserName";
import Connections from "./Connections";
import Cities from "./Cities";
import EmailAddress from "./EmailAddress";
import TrustBoxSlider from "./TrustBoxSlider";

function Form({ posts, plans }) {
  const [page, setPage] = useState(0);
  const [formData, setFormData] = useState({
    name: "",
    lastName: "",
    product: [],
    city: "",
    email: localStorage.getItem("email"),
    accepted: false,
    gifter: "true",
    connections: {},
    mailError: true,
    nameValid: true,
    lastNameValid: true,
    shop: "myirlandbox",
  });
  ReactGA.initialize("UA-39344816-2");
  ReactGA.pageview(window.location.pathname + window.location.search);
  const fetchData = async () => {
    try {
      const response = await fetch('https://myirelandbox.blackbird.marketing/api/klaviyoHandler', {
        method: 'POST', // Set the request method to POST
        headers: {
          'Content-Type': 'application/json', // Set the content type for JSON data
        },
        body: JSON.stringify(formData), // Replace with your actual data
      });

      const result = await response.json();
      // setData(result);
      console.log(result)
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  const FormTitle = [
    "Who is this for?",
    "What is your connection to Ireland?",
    "Where in Ireland would you most like to visit?",
    "What is your email?",
    "Pick a plan for " + formData.name + " :",
  ];
  const FormParagraph = [
    "Help us understand who will be receiving their MyIrelandBox!",
  ];
  const PageDisplay = () => {
    if (page === 0) {
      return <UserName formData={formData} setFormData={setFormData} />;
    } else if (page === 1) {
      return (
        <Connections
          formData={formData}
          setFormData={setFormData}
          page={page}
          setPage={setPage}
          step2={posts}
        />
      );
    } else if (page === 2) {
      return <Cities formData={formData} setFormData={setFormData} />;
    } else if (page === 3) {
      return <EmailAddress formData={formData} setFormData={setFormData} />;
    } else {
      return (
        <Product
          formData={formData}
          setFormData={setFormData}
          page={page}
          setPage={setPage}
          step5={posts}
          plans={plans}
        />
      );
    }
  };
  const [style, setStyle] = useState("cont");
  const agreement = () => {
    return <p>Agree</p>;
  };

  const handleClick = () => {
    window.open(
      `https://checkout.myirelandbox.com/checkout/buy/${formData.product.id}`,
      "_self"
    );
  };

  // console.log(formData);
  return (
    <div className="form">
      {/* <Unlockedoffer posts={posts} /> */}
      <div className="mobile-header-form">
        <div className="logoFormContainer">
          <Link
            to={
              window.location.href.indexOf("cw") != -1
                ? "/cw"
                : window.location.href.indexOf("gifters") != -1
                ? "/gifters"
                : window.location.href.indexOf("gift/") != -1
                ? "/gift"
                : "/"
            }
          >
             <LazyLoad height={200}>
            <img src={Logo} className="logoForm" />
            </LazyLoad>
          </Link>
        </div>
        {/* <FreeButlers /> */}
      </div>
      <div className="form-body">
        <div className="progressbar" id="progress-bar-gifting">
          <button>
            <img
              src={Step1}
              alt="step1"
              onClick={() => {
                setPage(0);
              }}
              style={page === 0 ? { opacity: "1" } : { opacity: "0.5" }}
            />
          </button>
          <div className="progress-line"></div>
          <button
            disabled={
              formData.name === "" ||
              formData.lastName === "" ||
              formData.nameValid === false ||
              formData.lastNameValid === false
            }
            onClick={() => {
              setPage(1);
            }}
          >
            <img
              src={Step2}
              alt="step2"
              style={page === 1 ? { opacity: "1" } : { opacity: "0.5" }}
            />
          </button>
          <div className="progress-line"></div>
          <button
            disabled={
              formData.name === "" ||
              formData.lastName === "" ||
              formData.nameValid === false ||
              formData.lastNameValid === false
            }
            onClick={() => {
              setPage(2);
            }}
          >
            <img
              src={Step3}
              alt="step3"
              style={page === 2 ? { opacity: "1" } : { opacity: "0.5" }}
            />
          </button>
          <div className="progress-line"></div>
          <button
            disabled={
              formData.name === "" ||
              formData.lastName === "" ||
              formData.nameValid === false ||
              formData.lastNameValid === false
            }
            onClick={() => {
              setPage(3);
            }}
          >
            <img
              src={Step4}
              alt="step4"
              style={page === 3 ? { opacity: "1" } : { opacity: "0.5" }}
            />
          </button>
          <div className="progress-line"></div>
          <button
            disabled={
              formData.accepted === false ||
              formData.mailError === false ||
              formData.email === "" ||
              formData.name === "" ||
              formData.lastName === ""
            }
            onClick={() => {
              setPage(4);
            }}
          >
            <img
              src={Step5}
              alt="step5"
              style={page === 4 ? { opacity: "1" } : { opacity: "0.5" }}
            />
          </button>
        </div>
        <div className="form-container"></div>
        <div className="headerForm">
          <h1>{FormTitle[page]}</h1>
        </div>
        <div className="paragraphForm">
          <p>{FormParagraph}</p>
        </div>

        <form
          action="https://cms.myirelandbox.com/wp-content/submitgifter.php"
          method="POST"
        >
          <div className="body">
            {PageDisplay()}
            {/* {showNameError()} */}
            <input
              name="email"
              value={formData.email}
              style={{ display: "none" }}
            />
            <input
              name="fname"
              value={formData.name}
              style={{ display: "none" }}
            />
            <input
              name="lname"
              value={formData.lastName}
              style={{ display: "none" }}
            />
            <input
              name="attraction"
              value={formData.city}
              style={{ display: "none" }}
            />
            <input
              name="gifter"
              value={formData.gifter}
              style={{ display: "none" }}
            />
            <input
              name="connection"
              value={formData.connections.Explain}
              style={{ display: "none" }}
            />
            <iframe
              id="test"
              name="dummyframe"
              style={{ display: "block", width: "150px", height: "150px" }}
            ></iframe>
          </div>
          <div
            className="submit-button-div"
            style={
              page != 3
                ? { backgroundColor: "transparent", display: "none" }
                : { backgroundColor: "#fffcf8" }
            }
          >
            <div
              className="goback-div"
              onClick={() => {
                setPage(page - 1);
              }}
              style={
                page == 0
                  ? { display: "g" }
                  : page === 1
                  ? { display: "none" }
                  : page === 2
                  ? { display: "none" }
                  : page === 4
                  ? { display: "none" }
                  : page === 3
                  ? { display: "flex" }
                  : { display: "none" }
              }
            >
              <BiChevronLeft size={20} className="submit-goback-icon" />
              <p
                className="goback"
                onClick={() => {
                  setPage(page - 1);
                }}
              >
                Go back
              </p>
            </div>
            <div
              className="goBack"
              onClick={() => {
                setPage(page - 1);
              }}
              style={page === 3 ? { display: "flex" } : { display: "none" }}
            >
              <BiChevronLeft size={30} style={{ paddingTop: "0px" }} />{" "}
            </div>
            <button
              type="submit"
              disabled={formData.mailError === false || formData.email === ""}
              style={
                page === 3
                  ? {
                      display: "flex",
                      borderTopLeftRadius: "0",
                      borderBottomLeftRadius: "0",
                    }
                  : { display: "none" }
              }
              formTarget="dummyframe"
              className="submitBtn"
              id="subscribeButton"
              onClick={() => {
                // valid  ate();
                if (formData.accepted === false) {
                  agreement();
                } else {
                  ReactPixel.track("Lead");
                  fetchData()
                  setPage((currPage) => currPage + 1);
                }
                ReactGA.event({
                  category: "Signup Flow Lead",
                  action: "Lead",
                  label: "Sign-up",
                });
              }}
            >
              <p>Continue </p>
              <VscArrowSmallRight size={25} className="rightArrow" />
            </button>
          </div>
        </form>

        <div
          className="formBtns"
          style={
            page === 1
              ? { display: "none" }
              : page === 3
              ? { display: "none" }
              : { display: "flex" }
          }
        >
          <div
            className="goback-div"
            style={page === 3 ? { display: "none" } : { display: "none" }}
            onClick={() => {
              setPage(page - 1);
            }}
          >
            <BiChevronLeft
              size={20}
              style={page === 0 ? { display: "none" } : { display: "none" }}
            />{" "}
            <p
              className="goback2"
              style={page === 0 ? { display: "none" } : { display: "none" }}
              onClick={() => {
                setPage(page - 1);
              }}
            >
              Go back
            </p>{" "}
          </div>
          <div
            className="goBack"
            onClick={() => {
              setPage(page - 1);
            }}
            style={
              page === 0
                ? { display: "none", opacity: "0", position: "absolute" }
                : { display: "flex" }
            }
          >
            <BiChevronLeft
              size={30}
              style={{ paddingTop: "0px" }}
              id="goback-svg"
            />{" "}
          </div>
          <button
            style={
              page === 0
                ? {
                    borderRadius: "0",
                  }
                : page === 1
                ? {
                    display: "none",
                    borderTopLeftRadius: "7px",
                    borderBottomLeftRadius: "7px",
                  }
                : page === 3
                ? {
                    display: "none",
                    borderTopLeftRadius: "7px",
                    borderBottomLeftRadius: "7px",
                  }
                : page === 4
                ? {
                    display: "none",
                    borderTopLeftRadius: "7px",
                    borderBottomLeftRadius: "7px",
                  }
                : {
                    display: "flex",
                    borderTopLeftRadius: "0",
                    borderBottomLeftRadius: "0",
                  }
            }
            disabled={
              page === FormTitle.length - 1 ||
              formData.name === "" ||
              formData.lastName === "" ||
              formData.nameValid === false ||
              formData.lastNameValid === false
            }
            onClick={() => {
              // validate();
              setPage((currPage) => currPage + 1);
            }}
            id={page === 0 ? "step_one_name_gift" : page === 1 ? "step_two_connection_gift" : page === 2 ? "step_three_attraction_gift" : page === 3 ? "step_four_email_gift" :page === 4 ? "step_five_plan_gift" : ""}
          >
            Continue <VscArrowSmallRight size={25} className="rightArrow" />
          </button>

          {/* <button
            className="subblyBtn"
            style={
              page === 4
                ? {
                    display: "flex",
                    borderTopLeftRadius: "0",
                    borderBottomLeftRadius: "0",
                  }
                : { display: "none" }
            }
            disabled={Object.keys(formData.product).length === 0}
            id="addToCartButton"
            onClick={() => {
              ReactPixel.track("AddToCart");
              localStorage.removeItem("email");

              handleClick();
            }}
          >
            <p>Continue</p>{" "}
            <VscArrowSmallRight size={25} className="rightArrow" />
          </button> */}
        </div>

        <div
          className="skipBtnDiv"
          style={
            page === 2
              ? { justifyContent: "center" }
              : page === 3
              ? { display: "none" }
              : page === 4
              ? { display: "none" }
              : page === 1
              ? {
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }
              : { display: "flex" }
          }
        >
          <div
            className="goback-div "
            onClick={() => {
              setPage(page - 1);
            }}
          >
            <BiChevronLeft
              size={20}
              style={
                page == 2
                  ? { display: "none" }
                  : page == 0
                  ? { display: "none" }
                  : { display: "block" }
              }
            />
            <p
              className="goback3"
              style={
                page === 1
                  ? {
                      display: "block",
                    }
                  : { display: "none" }
              }
              onClick={() => {
                setPage(page - 1);
              }}
            >
              Go back
            </p>
          </div>

          <button
            className="skipBtn"
            style={page === 0 ? { display: "none" } : { display: "flex" }}
            onClick={() => {
              setPage((currPage) => currPage + 1);
            }}
          >
            or, skip this step
          </button>
        </div>
      </div>
      <TrustBoxSlider />
    </div>
  );
}

export default Form;
