import React, { useState } from "react";
import { Link } from "react-router-dom";
import { AiOutlinePlayCircle } from "react-icons/ai";
import kath2 from "../../../../assets/kath.mp4";
import tick from "../../../../assets/tick.svg";
import kath from "../../../../assets/katttt.png";
import prof from "../../../../assets/kathprofile.png";
const Member = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const openPopup = () => {
    setIsOpen(true);
  };

  const closePopup = () => {
    setIsOpen(false);
  };
//   console.log("PROPS",props.posts.acf.youtube_EmbedTwo
// )
  return (
    <div className="member-bck">
      <div className="member-container">
        {isOpen && (
          <div className="popup-overlay">
            <div className="popup">
              <div className="popup-content">
              <iframe width="560" height="315" src={props.posts.acf.youtube_EmbedTwo.embed_link} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

                <div className="popUp-description">
                  <div>
                    <img src={prof}  alt="" />
                    <p className="kath">Katharine Keane Barrett</p>
                  </div>
                  <p className="popUp-scroll">
                  {props.posts.acf.youtube_EmbedTwo.popup_description}
                  </p>

                  <div className="popUp-buttons">
                    <Link to="/get-started" alt="#">
                      Subscribe Today
                    </Link>
                    <Link to="/gifting-form" alt="#">
                      View More
                    </Link>
                  </div>
                </div>
                <button onClick={closePopup}>X</button>
              </div>
            </div>
          </div>
        )}
        <div className="play-popUp" onClick={openPopup}>
          <img src= {props.posts.acf.youtube_EmbedTwo.image.url} />

          <div className="play-content">
            
            <h1> {props.posts.acf.youtube_EmbedTwo.shipping_note}

            </h1>
            <p className="hoverable">
              <AiOutlinePlayCircle />
              Play Now
            </p>
          </div>
        </div>
        <div className="memebr-display">
          <h1>{props.posts.acf.youtube_EmbedTwo.member_title} </h1>
          <ul>
            {/* <li>
              
              <img src={tick} />
              <b>FREE Irish handmade jewellery</b> (up to $70 in value)
            </li> */}
            <li>
              <img src={tick} />
              <div
     
      dangerouslySetInnerHTML={{ __html: props.posts.acf.youtube_EmbedTwo.perks_one }}
    ></div>
            </li>
            <li>
              <img src={tick} />
              {props.posts.acf.youtube_EmbedTwo.perks_two}
            </li>
            <li>
              <img src={tick} />
              {props.posts.acf.youtube_EmbedTwo.perks_three}
            </li>
          </ul>

          <h2>
            <b> {props.posts.acf.youtube_EmbedTwo.starting_price}</b>
          </h2>
          <p>+ Tracked Shipping </p>
          <Link
            to={
              window.location.href.includes("christmas-ecard")
                ? "/gifting-form"
                : "/get-started"
            }
          >
            {" "}
            Yes, I want a MyIrelandBox!
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Member;
